import 'modern-normalize/modern-normalize.css';
import React from 'react';
import Helmet from 'react-helmet';
import { Transition as ReactTransition } from 'react-transition-group';

import { historyExitingEventType, timeout } from '../../../gatsby-browser';

import './index.module.scss';

const Layout = ({ children }) => (
  <React.Fragment>
    <Helmet>
      <meta charSet="utf-8" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0, shrink-to-fit=no"
      />
      <link href="https://fonts.googleapis.com/css?family=Poppins:200,400" rel="stylesheet" />
      <title>Oops Typo</title>
    </Helmet>
    <Transition>{children}</Transition>
  </React.Fragment>
);

class Transition extends React.Component {
  state = {
    exiting: false
  };

  componentDidMount() {
    window.addEventListener(historyExitingEventType, this.listenerHandler);
  }

  componentWillUnmount() {
    window.removeEventListener(historyExitingEventType, this.listenerHandler);
  }

  static getDerivedStateFromProps({ exiting }) {
    if (exiting) {
      return { exiting: false };
    }
    return null;
  }

  listenerHandler = () => this.setState({ exiting: true });

  renderChildren = status => {
    const { children } = this.props;
    return React.Children.map(children, child => {
      const className = `${child.props.className || ``} ${status}`;
      return React.cloneElement(child, { className });
    });
  };

  render() {
    const { exiting } = this.state;

    const transitionProps = {
      timeout,
      appear: true,
      in: !exiting
    };

    return <ReactTransition {...transitionProps}>{this.renderChildren}</ReactTransition>;
  }
}

export default Layout;
